import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_FACTURACION_MASIVA
export default class ReenviarService {
  reenviar (payload) {
    return http.post(`${baseUrl}/reenviar/`, payload, {
      headers: {
        loading: true
      }
    })
  }

  reenviarAll () {
    return http.post(`${baseUrl}/reenviar-all`, {
      headers: {
        loading: true
      }
    })
  }
}
