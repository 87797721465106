<template>
    <section>
        <div class="h-full bg-white p-5 mt-10 rounded-xl border">
            <div class="rounded-t-xl">
                <button class="text-blue-700 bold text-md flex" @click="atras">
                    <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                    <p  class="bold text-lg">
                        Atras
                    </p>
                </button>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12 items-center mb-5">
                <div class="flex rounded-b-lg mx-5 card-detalles items-center">
                    <img class="mr-2" style="width: 22px; height: 20px" src="../../../../../public/img/icon_historial.svg" alt="Icono historial">
                    <p  class="bold text-lg">
                        Historial Evento
                    </p>
                </div>
                <button class="rounded-lg border flex mx-5 card-detalles" @click="filtroTodos">
                    <div class="p-3 bg-gray-800 h-full rounded-l-lg flex justify-center items-center">
                        <img style="width: 12px; height: 12px" src="../../../../../public/img/icon_todos_historial.svg" alt="Icono historial" />
                    </div>
                    <div class="p-2">
                        <p>Todos</p>
                        <p class="font-bold">{{ totalOvs }}</p>
                    </div>
                </button>
                <button @click="filtroProcesando" class="rounded-lg border flex mx-5 card-detalles">
                    <div style="background-color: #531EAF" class="p-3 h-full rounded-l-lg flex justify-center items-center">
                        <img style="width: 17px; height: 16px" src="../../../../../public/img/icon_historial_procesando.svg" alt="Icono historial" />
                    </div>
                    <div class="p-2">
                        <p>Procesando</p>
                        <p class="font-bold">{{ facturasProceso.length }}</p>
                    </div>
                </button>
                <button  @click="filtroGenerados" class="rounded-lg border flex mx-5 card-detalles">
                    <div style="background-color: #8BC34A" class="p-3 h-full rounded-l-lg flex justify-center items-center">
                        <img style="width: 28px; height: 28px" src="../../../../../public/img/icon_historial_generados.png" alt="Icono historial" />
                    </div>
                    <div class="p-2">
                        <p>Generados</p>
                        <p class="font-bold">{{ facturasGeneradas.length }}</p>
                    </div>
                </button>
                <button @click="filtroErrores" class="rounded-lg border flex mx-5 card-detalles">
                    <div class="p-3 h-full bg-red-500 rounded-l-lg flex justify-center items-center">
                        <img style="width: 28px; height: 28px" src="../../../../../public/img/icon_historial_errores.png" alt="Icono historial" />
                    </div>
                    <div class="p-2">
                        <p>Errores</p>
                        <p class="font-bold">{{ facturasError.length }}</p>
                    </div>
                </button>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12 my-3">
                <div class="w-full">
                    <input type="text" v-model="ovNumber" @keyup.enter="insertarID" placeholder="Buscar Orden" class="text-sm block placeholder-gray-700 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </div>
                <div>
                    <select id="autor" v-model="autor" style="--tw-shadow: none" class="form-select mr-2 appearance-none block w-90 p-2 text-sm font-normal text-gray-900 bg-white bg-clip-padding bg-no-repeat border drop-shadow-none border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Bodega(s)">
                        <option v-bind:value="autor" disabled>Autor</option>
                        <option v-for="(autor, i) in autores" :key="i"> {{ autor }} </option>
                    </select>
                </div>
                <div></div>
                <div></div>
                <div class="grid grid-cols-2">
                    <button @click="buscar" type="button" style="height: fit-content;" class="flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm p-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                        Buscar
                    </button>
                    <button @click="limpiarFiltros" type="button" style="height: fit-content;" class="flex items-center text-gray-500 bg-transparent focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Limpiar
                    </button>
                </div>
            </div>
            <div>
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-white uppercase bg-gray-800 dark:bg-gray-700 dark:text-gray-400">
                            <tr class="divide-x">
                                <th scope="col" class="px-3 py-3 text-center">
                                    #OV
                                </th>
                                <th scope="col" class="px-3 py-3 text-center">
                                    Cliente
                                </th>
                                <th scope="col" class="px-3 py-3 text-center">
                                    Total OV
                                </th>
                                <th scope="col" class="px-3 py-3 text-center">
                                    Creado por
                                </th>
                                <th scope="col" class="px-3 py-3 text-center">
                                    Fecha creación
                                </th>
                                <th scope="col" class="px-3 py-3 text-center">
                                    Comentarios
                                </th>
                                <th scope="col" class="px-3 py-3 text-center">
                                    Estado
                                </th>
                                <th scope="col" class="px-3 py-3 text-center">
                                    Numero de factura
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="(factura, n) in listadoFacturas" :key="n">
                            <tr class="border-b dark:bg-gray-800 dark:border-gray-700 divide-x">
                                <td class="px-3 py-1 text-center">
                                    {{ factura.NumOrden }}
                                </td>
                                <td class="px-3 py-1 text-center">
                                    {{ factura.Cliente }}
                                </td>
                                <td class="px-3 py-1 text-center">
                                    {{ $h.formatCurrency(parseInt(factura.TotalOv)) }}
                                </td>
                                <td class="px-3 py-1 text-center">
                                    {{ factura.CreadoPor }}
                                </td>
                                <td class="px-3 py-1 text-center">
                                    {{ dayjs(factura.FechaCreacion).format('DD-MM-YYYY') }}
                                </td>
                                <td class="px-3 py-1 text-center">
                                    {{ factura.Comentarios }}
                                </td>
                                <td class="px-3 py-1 text-center">
                                    <div v-if="!factura.Estado" class="flex justify-center">
                                        <img class="mr-2" style="width: 10px; height: 10px" src="../../../../../public/img/icon_historial_morado.svg" alt="Icono historial" />
                                    </div>
                                    <div v-if="factura.Estado === 1" class="flex justify-center">
                                        <img class="mr-2" style="width: 10px; height: 10px" src="../../../../../public/img/icon_historial_verde.svg" alt="Icono historial" />
                                    </div>
                                    <div v-if="factura.Estado === 2" class="flex justify-center">
                                        <button @click="reenviar(factura.segFacturacionId)" class="rounded-md py-1 px-2 text-red-600 bg-red-200 drop-shadow-md">Reenviar</button>
                                    </div>
                                </td>
                                <td class="px-3 py-1 text-center">
                                    {{ factura.NumFactura }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-12 p-5">
                        <div class="flex items-center justify-center text-gray-900">
                            Ver
                            <select v-on:change="selectedNumRows" v-model="numRowSelected" id="countries" class="ml-2 w-min bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option v-for="(num, a) in numerosRows" :value="num" :key="a">{{ num }}</option>
                                <option :value="totalFacturas">Todos</option>
                            </select>
                        </div>
                        <div>
                            <Paginator
                            @page="onPage($event)"
                            v-model:first="offset"
                            :rows="numRowSelected"
                            :page-class="'page-item'"
                            :container-class="'pagination'"
                            :totalRecords="totalFacturas" />
                        </div>
                        <div class="text-gray-800 flex items-center justify-center">
                            Mostrando de 1 a {{ numRowSelected }} de {{ totalFacturas }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { ref, onMounted, computed } from 'vue'
    import dayjs from 'dayjs'
    import { useRouter, useRoute } from 'vue-router'
    import HistorialDetalleService from '../services/historialDetalle.service'
    import ReenviarService from '../services/reenviar.service'
    import TotalHistorialDetalleService from '../services/totalHistorialDetalle.service'
    export default {
        name: 'SegFacturacionHistorialDetalle',
        setup () {
            const _Facturas = ref(new HistorialDetalleService())
            const _Reenviar = ref(new ReenviarService())
            const _Total = ref(new TotalHistorialDetalleService())
            const listadoFacturas = ref([])
            const router = useRouter()
            const route = useRoute()
            const totalFacturas = ref('')
            const numRowSelected = ref(10)
            const numerosRows = [10, 50, 100, 250, 500]
            const facturaId = ref('')
            const facturas = ref([])
            const modalidad = ref('')
            const autor = ref('')
            const offset = ref(0)
            const autores = ref([])
            const ovNumber = ref('')
            const estado = ref('')
            const facturasProceso = ref([])
            const facturasGeneradas = ref([])
            const facturasError = ref([])
            const totalOvs = ref('')
            const reenviar = (id) => {
                _Reenviar.value.reenviar(id).then(({ data }) => {
                    listarFacturas()
                    totalizar()
                })
            }
            const filters = computed(() => {
                return {
                    ovs: ovNumber.value,
                    autor: autor.value,
                    estado: estado.value
                }
            })
            const totalizar = () => {
                _Total.value.getTotalHistorialDetalle(route.params.id).then(({ data }) => {
                    totalOvs.value = data.count
                    facturasError.value = data.rows.filter(a => a.Estado === 2)
                    facturasGeneradas.value = data.rows.filter(a => a.Estado === 1)
                    facturasProceso.value = data.rows.filter(a => a.Estado === 0)
                    const autObj = data.rows.map(a => a.CreadoPor)
                    autores.value = autObj.filter((item, index) => {
                        return autObj.indexOf(item) === index
                    })
                })
            }
            const listarFacturas = () => {
                const params = {
                    limit: numRowSelected.value,
                    offset: offset.value,
                    ...filters.value
                }
                _Facturas.value.getHistorialDetalle(route.params.id, params).then(({ data }) => {
                    listadoFacturas.value = data.rows
                    totalFacturas.value = data.count
                })
            }
            const selectedNumRows = () => {
              offset.value = 0
              listarFacturas()
            }
            const limpiarFiltros = () => {
                ovNumber.value = ''
                autor.value = ''
                estado.value = ''
                listarFacturas()
            }
            const filtroTodos = () => {
                estado.value = ''
                listarFacturas()
            }
            const filtroProcesando = () => {
                estado.value = 0
                listarFacturas()
            }
            const filtroGenerados = () => {
                estado.value = 1
                listarFacturas()
            }
            const filtroErrores = () => {
                estado.value = 2
                listarFacturas()
            }
            const insertarID = () => {
                facturas.value.push(facturaId.value)
                facturaId.value = ''
            }
            const borrarFacturaId = (i) => {
                facturas.value.splice(i, 1)
            }
            const buscar = () => {
              offset.value = 0
              numRowSelected.value = 10
              listarFacturas()
            }
            const atras = () => {
                router.back()
            }
            const onPage = (event) => {
                listarFacturas()
            }
            onMounted(() => {
                listarFacturas()
                totalizar()
            })
            return {
                listadoFacturas,
                dayjs,
                atras,
                totalFacturas,
                numRowSelected,
                numerosRows,
                listarFacturas,
                insertarID,
                facturas,
                facturaId,
                borrarFacturaId,
                modalidad,
                autor,
                autores,
                offset,
                buscar,
                ovNumber,
                filtroTodos,
                filtroProcesando,
                filtroGenerados,
                filtroErrores,
                onPage,
                limpiarFiltros,
                reenviar,
                facturasProceso,
                facturasError,
                facturasGeneradas,
                totalOvs,
                selectedNumRows
            }
        }
    }
</script>
<style scoped>
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
    }

    tr:nth-child(even) {
        background-color: #F6F8FB;
    }
    ::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
        background: #F1F5F9 0% 0% no-repeat padding-box;
        border-color: #E3F2FD;
        color: #495057;
    }
    ::v-deep(.p-paginator .p-paginator-pages .p-paginator-page){
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    ::v-deep(.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last){
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    ::v-deep(.p-paginator .p-paginator-prev, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    ::v-deep(.p-paginator .p-paginator-next, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    ::v-deep(.p-paginator .p-paginator-last, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    .card-detalles {
        width: 173px;
        height: 60px;
    }
</style>
